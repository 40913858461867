import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  VStack,
  Box,
  Badge
} from '@chakra-ui/react';
import { ScoreCard } from './ScoreCards';

export const ModalSpinner = ({ isOpen, onOpen, showSpinner, onClose, keysData }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = React.useRef(null)
   
    return (
      <>
        <Modal finalFocusRef={finalRef} size={"full"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader><Badge size={"xl"}>{showSpinner ? 'Submitting' : 'Submitted'}</Badge></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            

            {/* <Wrap spacing="30px" justify="center">
            {keysData.testimonials.map((url, index) => (
              <WrapItem key={index}>
                <Center w="120px" h="80px">
                  <Image src={url} alt={`Image ${index + 1}`} />
                </Center>
              </WrapItem>
            ))}
          </Wrap> */}

            {showSpinner && <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />}  
            
            <Box p={5}>
                <VStack spacing={8}>
                    {keysData.testimonials.map((student, index) => (
                    <ScoreCard key={index} {...student} />
                    ))}
                </VStack>
            </Box>
          
            </ModalBody>
  
            
          </ModalContent>
        </Modal>
      </>
    )
  }
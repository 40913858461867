import React, { useEffect } from 'react';

const SignInButton = ({setPhone}) => {

    useEffect(() => {
        // Create the script element for the sign-in button
        const script = document.createElement('script');
        script.src = 'https://www.phone.email/sign_in_button_v1.js';
        script.async = true;

        // Add the client-id div
        const div = document.createElement('div');
        div.className = 'pe_signin_button';
        div.setAttribute('data-client-id', '17173691806152637190');
       

        // Append the div to the DOM
        const signInContainer = document.getElementById('sign-in-container');

        signInContainer.appendChild(div);

       

        // Append the script to the div
        div.appendChild(script);

        //document.getElementById('btn_ph_login_text').innerText = "Verify phone";
        // Define the global listener function
        window.phoneEmailListener = (userObj) => {
            // const user_json_url = userObj.user_json_url;
            // console.log(user_json_url);
            setPhone(userObj.user_phone_number);
            //alert(`SUCCESS !!\nYour phone number ${user_country_code} ${user_phone_number} has been authenticated.`);
            // You can submit your form here or redirect the user to the post-login dashboard page
            // Send user_json_url to your backend to retrieve user info (i.e., country code and phone number) from this URL.
        };

        // Cleanup function to remove the script and div when the component unmounts
        return () => {
            signInContainer.removeChild(div);
        };
    }, );

    return <div id="sign-in-container"></div>;
};

export default SignInButton;

import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {ChakraProvider } from '@chakra-ui/react'
import { LoadForm } from './components/LoadForm';

function App() {

 const [phone, setPhone] = useState('');

 console.log(process.env.REACT_APP_TOKEN);

  return (
    <ChakraProvider >
      <Router>
         <Routes>
            <Route path='/institute'>
                  {/* <Route path=':instituteId' element={phone.length > 0 ? <EducationForm isOpen={isOpen} onOpen={onOpen} onClose={onClose} phoneNumber={phone}/> :  cards} /> */}
                  <Route path=':instituteId' element={<LoadForm phoneNumber={phone} setPhone={setPhone}/>} />
            </Route>
            <Route path='/immigration'>
                  {/* <Route path=':instituteId' element={phone.length > 0 ? <EducationForm isOpen={isOpen} onOpen={onOpen} onClose={onClose} phoneNumber={phone}/> :  cards} /> */}
                  <Route path=':instituteId/:source' element={<LoadForm phoneNumber={phone} setPhone={setPhone}/>} />
            </Route>
         </Routes>
      </Router>
    </ChakraProvider>
      
  );
}

export default App;

import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Heading,
  Stepper,
  useSteps,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepSeparator,
  Stack,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  Checkbox,
  CheckboxGroup,
  HStack
} from '@chakra-ui/react';
import { ModalSpinner } from '../components/ModalSpinner';

const ImmigrationForm = ({phoneNumber, isOpen, onOpen, onClose, keysData, source}) => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    guardianName: '',
    guardianPhone: '',
    startDate: '',
    study: '',
    destination: '',
    schoolCollege: '',
    year: '',
    percentage: '',
    twelfthStream: '',
    twelfthSchoolCollege: '',
    twelfthYear: '',
    twelfthCgpaPercentage: '',
    graduationStream: '',
    graduationCollege: '',
    graduationYear: '',
    graduationCgpaPercentage: '',
    mastersStream: '',
    mastersSchoolCollege: '',
    mastersYear: '',
    mastersCgpaPercentage: '',
    diplomaStream: '',
    diplomaSchoolCollege: '',
    diplomaYear: '',
    diplomaPercentage: '',
    reading: '',
    listening: '',
    speaking: '',
    writing: '',
    overall: '',
    organization1: '',
    position1: '',
    joiningDate1: '',
    leavingDate1: '',
    totalExperience1: '',
    organization2: '',
    position2: '',
    joiningDate2: '',
    leavingDate2: '',
    totalExperience2: ''
  });

  

  const [showSpinner, setShowSpinner] = useState(true);
  const [checked, setChecked] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if(name === 'ieltsPte'){
      setChecked(!checked);
    }else{
      setFormData({ ...formData, [name]: value });
    }    
  };

  const handleChecks = (values) => {
    setSelectedValues(values);
  };

  const validatePhoneNumber = (phone) => /^\d{10}$/.test(phone);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ( !validatePhoneNumber(formData.guardianPhone)) {
      toast({
        title: 'Invalid phone number.',
        description: 'Phone number must be 10 digits.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setActiveStep(0);
      return;
    }

    if ( formData.destination.length === 0 ) {
      toast({
        title: 'Select where you want to go',
        description: 'Please select where you are planning to go as this is going to help us evaluate your profile.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setActiveStep(0);
      return;
    }

    if ( formData.study.length === 0 ) {
      toast({
        title: 'Please select how we can help you? ',
        description: 'Please help us know what kind of services you need',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setActiveStep(0);
      return;
    }

    // Submit form data
    addData();
  };

  const appId = keysData?.appId;
  const token = process.env.REACT_APP_TOKEN;
  const url = `https://api.airtable.com/v0/${appId}/Students`
  const addData = async () => {
        try {
          console.log('Effect is running');
          onOpen();
          //setLoading(true);
          fetch(url,  {
              method: 'POST',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                records: [
                  {
                    fields: {
                        Name: formData.name,
                        Phone: '+91 ' + phoneNumber,
                        Email: formData.email,
                        source: source,
                        Role: "aspirant",
                        address: '',
                        Destination: [
                            formData.destination
                        ],
                        'inquiry': formData.study,
                        GuardianName: formData.guardianName,
                        GuardianPhone: '+91 ' + formData.guardianPhone,
                        schoolCollege: formData.schoolCollege,
                        year: formData?.year,
                        percentage: formData?.percentage,
                        reading: formData?.reading,
                        writing: formData?.writing,
                        speaking: formData?.speaking,
                        listening: formData?.listening,
                        overall: formData?.overall,
                        twelfthStream: formData.twelfthStream,
                        twelfthSchoolCollege: formData.twelfthSchoolCollege,
                        twelfthYear: formData.twelfthYear,
                        twelfthCgpaPercentage: formData.twelfthCgpaPercentage,
                        graduationStream: formData.graduationStream,
                        graduationCollege: formData.graduationCollege,
                        graduationYear: formData.graduationYear,
                        graduationCgpaPercentage: formData.graduationCgpaPercentage,
                        mastersStream: formData.mastersStream,
                        mastersSchoolCollege: formData.mastersSchoolCollege,
                        mastersYear: formData.mastersYear,
                        mastersCgpaPercentage: formData.mastersCgpaPercentage,
                        diplomaStream: formData.diplomaStream,
                        diplomaSchoolCollege: formData.diplomaSchoolCollege,
                        diplomaYear: formData.diplomaYear,
                        diplomaPercentage: formData.diplomaPercentage,
                        referral: selectedValues,
                        organization1: formData.organization1,
                        position1: formData.position1,
                        joiningDate1: formData.joiningDate1,
                        leavingDate1: formData.leavingDate1,
                        totalExperience1: formData.totalExperience1,
                        organization2: formData.organization2,
                        position2: formData.position2,
                        joiningDate2: formData.joiningDate2,
                        leavingDate2: formData.leavingDate2,
                        totalExperience2: formData.totalExperience2
                    }
                  }
                ]
              })}
              )
          .then((res) => res.json())
          .then((data) => {

            setShowSpinner(false);
            setFormData({
                name: '',
                email: '',
                phone: '',
                guardianName: '',
                guardianPhone: '',
                startDate: '',
                study: '',
                destination: '',
            });
            window.location.href = `instagram://user?username=${keysData?.instaUsername}`;
       
          })
          .catch((error) => {
            console.log(error);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    
    }

    //first main block
    const formBlock = ( <Box w="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg" m="auto" mt="10">
    <Heading fontSize="2xl" mb="6" textAlign="center">Welcome 👋 to {keysData?.name}</Heading>
    <form onSubmit={handleSubmit}>
      <VStack spacing="4">
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your full name" />
        </FormControl>

        <FormControl id="address" isRequired>
          <FormLabel>Address</FormLabel>
          <Input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" />
        </FormControl>
        
        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email Address" />
        </FormControl>
        
        <FormControl id="phone" isRequired>
          <FormLabel>Phone</FormLabel>
          <Input type="tel"  name="phone" value={phoneNumber} onChange={handleChange} isDisabled placeholder="Your Phone Number" />
        </FormControl>
        
        <FormControl id="guardian-name" isRequired>
          <FormLabel>Guardian's Name</FormLabel>
          <Input type="text" name="guardianName" value={formData.guardianName} onChange={handleChange} placeholder="Father or Mother's name" />
        </FormControl>
        
        <FormControl id="guardian-phone" isRequired>
          <FormLabel>Guardian's Phone</FormLabel>
          <Input type="tel" name="guardianPhone" value={formData.guardianPhone} onChange={handleChange} placeholder="Your Phone Number" />
        </FormControl>

        
        <FormControl id="study" isRequired>
          <FormLabel>How we can help you?</FormLabel>
          <Select name="study" value={formData.study} onChange={handleChange} placeholder="Select option">
            <option value="IELTS">IELTS</option>
            <option value="PTE">PTE</option>
            <option value="spoken english">Spoken English</option>
            <optgroup label="Visa">
              <option value="Visitor">Visitor</option>
              <option value="Study">Study</option>
              <option value="Sowp">SOWP</option>
          </optgroup>
          </Select>
        </FormControl>
        
        <FormControl id="destination" isRequired>
          <FormLabel>Where are you planning to go?</FormLabel>
          <Select name="destination" value={formData.destination} onChange={handleChange} placeholder="Select destination">
            <option value="Canada">Canada</option>
            <option value="USA">US</option>
            <option value="UK">UK</option>
            <option value="Australia">AUS</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Europe">Europe</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
        

      </VStack>
    </form>
          </Box>); 


const accordinBlock = (
    <>
    <Center p={4}><Heading fontSize={'2xl'}>Education 🎓</Heading></Center>
    <Center p={4}><Heading fontSize={'xl'}>Language Proficiency</Heading></Center>
    {/* <Center p={4} >
      <Checkbox name='ieltsPte' size='lg' isChecked={checked} onChange={handleChange}>
        Have you taken IELTS or PTE exam before?
      </Checkbox>
      <br/>
     </Center> */}
     { <Center>
      <HStack p={4} spacing={2}>
            <FormControl id="reading" mb={4}>
              <FormLabel>Reading</FormLabel>
              <Input name="reading" type="number" value={formData.reading} onChange={handleChange} />
            </FormControl>
            <FormControl id="listening" mb={4}>
              <FormLabel>Listening</FormLabel>
              <Input name="listening" type="number" value={formData.listening} onChange={handleChange} />
            </FormControl>
            <FormControl id="speaking" mb={4}>
              <FormLabel>Speaking</FormLabel>
              <Input name="speaking" type="number" value={formData.speaking} onChange={handleChange} />
            </FormControl>
            <FormControl id="writing" mb={4}>
              <FormLabel>Writing</FormLabel>
              <Input name="writing" type="number" value={formData.writing} onChange={handleChange} />
            </FormControl>
            <FormControl id="overall" mb={4}>
              <FormLabel>Overall</FormLabel>
              <Input name="overall" type="number" value={formData.overall} onChange={handleChange} />
            </FormControl>
          </HStack>
    </Center>}
    
     
    <Accordion p={12} bg={useColorModeValue('gray.50', 'gray.800')} allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
                <Heading fontSize={'2xl'}>10th</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack spacing={4}>
            <FormControl id="schoolCollege" mb={4}>
              <FormLabel>School/College</FormLabel>
              <Input name="schoolCollege" type="text" value={formData.schoolCollege} onChange={handleChange} />
            </FormControl>
            <FormControl id="year" mb={4}>
              <FormLabel>Year</FormLabel>
              <Input name="year" type="text" value={formData.year} onChange={handleChange} />
            </FormControl>
            <FormControl id="percentage" mb={4}>
              <FormLabel>Percentage</FormLabel>
              <Input name="percentage" type="text" value={formData.percentage} onChange={handleChange} />
            </FormControl>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
                <Heading fontSize={'2xl'}>12th</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack spacing={4}>
            <FormControl id="twelfthStream" mb={4}>
              <FormLabel>12th Stream</FormLabel>
              <Input name="twelfthStream" type="text" value={formData.twelfthStream} onChange={handleChange} />
            </FormControl>
            <FormControl id="twelfthSchoolCollege" mb={4}>
              <FormLabel>12th School/College</FormLabel>
              <Input name="twelfthSchoolCollege" type="text" value={formData.twelfthSchoolCollege} onChange={handleChange} />
            </FormControl>
            <FormControl id="twelfthYear" mb={4}>
              <FormLabel>12th Year</FormLabel>
              <Input name="twelfthYear" type="text" value={formData.twelfthYear} onChange={handleChange} />
            </FormControl>
            <FormControl id="twelfthCgpaPercentage" mb={4}>
              <FormLabel>12th CGPA/Percentage</FormLabel>
              <Input name="twelfthCgpaPercentage" type="text" value={formData.twelfthCgpaPercentage} onChange={handleChange} />
            </FormControl>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
            <Heading fontSize={'2xl'}>Graduation</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack spacing={4}>
            <FormControl id="graduationStream" mb={4}>
              <FormLabel>Graduation Stream</FormLabel>
              <Input name="graduationStream" type="text" value={formData.graduationStream} onChange={handleChange} />
            </FormControl>
            <FormControl id="graduationCollege" mb={4}>
              <FormLabel>Graduation College</FormLabel>
              <Input name="graduationCollege" type="text" value={formData.graduationCollege} onChange={handleChange} />
            </FormControl>
            <FormControl id="graduationYear" mb={4}>
              <FormLabel>Graduation Year</FormLabel>
              <Input name="graduationYear" type="text" value={formData.graduationYear} onChange={handleChange} />
            </FormControl>
            <FormControl id="graduationCgpaPercentage" mb={4}>
              <FormLabel>Graduation CGPA/Percentage</FormLabel>
              <Input name="graduationCgpaPercentage" type="text" value={formData.graduationCgpaPercentage} onChange={handleChange} />
            </FormControl>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
            <Heading fontSize={'2xl'}>Masters</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack spacing={4}>
            <FormControl id="mastersStream" mb={4}>
              <FormLabel>Masters Stream</FormLabel>
              <Input name="mastersStream" type="text" value={formData.mastersStream} onChange={handleChange} />
            </FormControl>
            <FormControl id="mastersSchoolCollege" mb={4}>
              <FormLabel>Masters School/College</FormLabel>
              <Input name="mastersSchoolCollege" type="text" value={formData.mastersSchoolCollege} onChange={handleChange} />
            </FormControl>
            <FormControl id="mastersYear" mb={4}>
              <FormLabel>Masters Year</FormLabel>
              <Input name="mastersYear" type="text" value={formData.mastersYear} onChange={handleChange} />
            </FormControl>
            <FormControl id="mastersCgpaPercentage" mb={4}>
              <FormLabel>Masters CGPA/Percentage</FormLabel>
              <Input name="mastersCgpaPercentage" type="text" value={formData.mastersCgpaPercentage} onChange={handleChange} />
            </FormControl>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
                <Heading fontSize={'2xl'}>Diploma</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
           <FormControl id="diplomaStream" mb={4}>
              <FormLabel>Diploma Stream</FormLabel>
              <Input name="diplomaStream" type="text" value={formData.diplomaStream} onChange={handleChange} />
            </FormControl>
            <FormControl id="diplomaCollege" mb={4}>
              <FormLabel>Diploma College</FormLabel>
              <Input name="diplomaCollege" type="text" value={formData.diplomaCollege} onChange={handleChange} />
            </FormControl>
            <FormControl id="diplomaYear" mb={4}>
              <FormLabel>Diploma Year</FormLabel>
              <Input name="diplomaYear" type="text" value={formData.diplomaYear} onChange={handleChange} />
            </FormControl>
            <FormControl id="diplomaPercentage" mb={4}>
              <FormLabel>Diploma Percentage</FormLabel>
              <Input name="diplomaPercentage" type="text" value={formData.diplomaPercentage} onChange={handleChange} />
            </FormControl>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </>
 );

 const workBlock = (
  <>
  <Center p={4}><Heading fontSize={'2xl'}>Work experience 🧑‍💼</Heading></Center>
  <Accordion p={12} bg={useColorModeValue('gray.50', 'gray.800')} allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
            <Heading fontSize={'2xl'}>Job 1</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Stack spacing={4}>
        <FormControl id="organization1" mb={4}>
          <FormLabel>Organization</FormLabel>
          <Input name="organization1" type="text" value={formData.organization1} onChange={handleChange} />
        </FormControl>
        <FormControl id="position1" mb={4}>
          <FormLabel>Position</FormLabel>
          <Input name="position1" type="text" value={formData.position1} onChange={handleChange} />
        </FormControl>
        <FormControl id="joiningDate1" mb={4}>
          <FormLabel>Joining Date</FormLabel>
          <Input name="joiningDate1" type="date" value={formData.joiningDate1} onChange={handleChange} />
        </FormControl>
        <FormControl id="leavingDate1" mb={4}>
          <FormLabel>Leaving Date</FormLabel>
          <Input name="leavingDate1" type="date" value={formData.leavingDate1} onChange={handleChange} />
        </FormControl>
        <FormControl id="totalExperience1" mb={4}>
          <FormLabel>Total Experience</FormLabel>
          <Input name="totalExperience1" type="text" value={formData.totalExperience1} onChange={handleChange} />
        </FormControl>
      </Stack>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
            <Heading fontSize={'2xl'}>Job 2</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Stack spacing={4}>
        <FormControl id="organization2" mb={4}>
          <FormLabel>Organization</FormLabel>
          <Input name="organization2" type="text" value={formData.organization2} onChange={handleChange} />
        </FormControl>
        <FormControl id="position2" mb={4}>
          <FormLabel>Position</FormLabel>
          <Input name="position2" type="text" value={formData.position2} onChange={handleChange} />
        </FormControl>
        <FormControl id="joiningDate2" mb={4}>
          <FormLabel>Joining Date</FormLabel>
          <Input name="joiningDate2" type="date" value={formData.joiningDate2} onChange={handleChange} />
        </FormControl>
        <FormControl id="leavingDate2" mb={4}>
          <FormLabel>Leaving Date</FormLabel>
          <Input name="leavingDate2" type="date" value={formData.leavingDate2} onChange={handleChange} />
        </FormControl>
        <FormControl id="totalExperience2" mb={4}>
          <FormLabel>Total Experience</FormLabel>
          <Input name="totalExperience2" type="text" value={formData.totalExperience2} onChange={handleChange} />
        </FormControl>
      </Stack>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
<Heading p={4} fontSize={'2xl'}>Who referred us?</Heading>
  <Center>
      <HStack  p={4} spacing={4}>
      <CheckboxGroup size={'lg'} colorScheme='green' value={selectedValues} onChange={handleChecks}>
            <Checkbox value="social media">Social Media</Checkbox>
            <Checkbox value="tv">TV</Checkbox>
            <Checkbox value="friend">Friend</Checkbox>
            <Checkbox value="other">Other</Checkbox>
          </CheckboxGroup>
      </HStack >
    </Center>
</>
 )

 const steps = [
    { title: 'First', description: 'Contact Info', data: <>{formBlock} <Button onClick={() => setActiveStep(1)} colorScheme="blue"  width="full">Next</Button></> },
    { title: 'Second', description: 'Date & Time', data: <> {accordinBlock} <Button onClick={() => setActiveStep(2)} colorScheme="blue"  width="full">Next</Button></> },
    { title: 'Third', description: 'Select Rooms', data: workBlock },
  ] 
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 2,
  })
  return (
    <>
    <ModalSpinner isOpen={isOpen} onOpen={onOpen} showSpinner={showSpinner} onClose={onClose} keysData={keysData}/>
    <Stepper size='lg' index={activeStep}>
        {steps.map((step, index) => (
          <Step key={index} onClick={() => setActiveStep(index)}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
  
         
            <Box flexShrink='0'>
                    
            </Box>
  
            <StepSeparator />
          </Step>
        ))}
        
      </Stepper>
      
      {steps[activeStep]?.data}
      {activeStep === 2 && <Button colorScheme="blue" onClick={handleSubmit} width="full">Submit</Button>}
    </>
  );
};


  
export default ImmigrationForm;

import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Heading,
} from '@chakra-ui/react';
import { ModalSpinner } from '../components/ModalSpinner';

const EducationForm = ({phoneNumber, isOpen, onOpen, onClose, keysData }) => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    guardianName: '',
    guardianPhone: '',
    startDate: '',
    study: '',
    destination: '',
  });

  const [showSpinner, setShowSpinner] = useState(true);

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  

  const validatePhoneNumber = (phone) => /^\d{10}$/.test(phone);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ( !validatePhoneNumber(formData.guardianPhone)) {
      toast({
        title: 'Invalid phone number.',
        description: 'Phone number must be 10 digits.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // Submit form data
    addData();
  };

  const appId = keysData?.appId;
  const token = process.env.REACT_APP_TOKEN;
  const url = `https://api.airtable.com/v0/${appId}/Students`
  const addData = async () => {
        try {
          console.log('Effect is running');
          onOpen();
          //setLoading(true);
          fetch(url,  {
              method: 'POST',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                records: [
                  {
                    fields: {
                      Name: formData.name,
                      Phone: '+91 ' + phoneNumber,
                      Email: formData.email,
                      Institute: keysData.instituteId,
                      Role: "aspirant",
                      "Enroll Date": formData.startDate,
                      Destination: [
                        formData.destination
                      ],
                      GuardianName: formData.guardianName,
                      GuardianPhone: '+91 ' + formData.guardianPhone,
                    }
                  }
                ]
              })}
              )
          .then((res) => res.json())
          .then((data) => {

            setShowSpinner(false);
            setFormData({
                name: '',
                email: '',
                phone: '',
                guardianName: '',
                guardianPhone: '',
                startDate: '',
                study: '',
                destination: '',
            });
            window.location.href = `instagram://user?username=${keysData?.instaUsername}`;
       
          })
          .catch((error) => {
            console.log(error);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    
    }

  return (
    <>
    <ModalSpinner isOpen={isOpen} onOpen={onOpen} showSpinner={showSpinner} onClose={onClose} keysData={keysData}/>
    <Box w="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg" m="auto" mt="10">
      <Heading fontSize="2xl" mb="6" textAlign="center">Welcome 👋 to {keysData?.name}</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing="4">
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your full name" />
          </FormControl>
          
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email Address" />
          </FormControl>
          
          <FormControl id="phone" isRequired>
            <FormLabel>Phone</FormLabel>
            <Input type="tel"  name="phone" value={phoneNumber} onChange={handleChange} isDisabled placeholder="Your Phone Number" />
          </FormControl>
          
          <FormControl id="guardian-name" isRequired>
            <FormLabel>Guardian's Name</FormLabel>
            <Input type="text" name="guardianName" value={formData.guardianName} onChange={handleChange} placeholder="Father or Mother's name" />
          </FormControl>
          
          <FormControl id="guardian-phone" isRequired>
            <FormLabel>Guardian's Phone</FormLabel>
            <Input type="tel" name="guardianPhone" value={formData.guardianPhone} onChange={handleChange} placeholder="Your Phone Number" />
          </FormControl>
          
          <FormControl id="start-date" >
            <FormLabel>When would you like to start</FormLabel>
            <Input type="date" name="startDate" value={formData.startDate} onChange={handleChange} placeholder="Start date" />
          </FormControl>
          
          <FormControl id="study" isRequired>
            <FormLabel>What do you want to study</FormLabel>
            <Select name="study" value={formData.study} onChange={handleChange} placeholder="Select option">
              <option value="ielts">IELTS</option>
              <option value="pte">PTE</option>
              <option value="spoken english">Spoken English</option>
              <option value="visa">Visa</option>
            </Select>
          </FormControl>
          
          <FormControl id="destination" isRequired>
            <FormLabel>Where are you planning to go?</FormLabel>
            <Select name="destination" value={formData.destination} onChange={handleChange} placeholder="Select destination">
              <option value="Canada">Canada</option>
              <option value="USA">US</option>
              <option value="UK">UK</option>
              <option value="Australia">AUS</option>
              <option value="New Zealand">New Zealand</option>
              
            </Select>
          </FormControl>
          
          <Button colorScheme="blue" type="submit" width="full">Submit</Button>
        </VStack>
      </form>
    </Box>
    </>
  );
};

export default EducationForm;

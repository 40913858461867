import { Box, Image, Text, VStack, HStack, Badge } from "@chakra-ui/react";

export const ScoreCard = ({ name, testType, imageUrl }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bg="white"
      boxShadow="md"
      p={4}
      maxW="sm"
      mx="auto"
    >
      <VStack spacing={4} align="start">
        <HStack justifyContent="space-between" width="100%">
          <Text fontWeight="bold" fontSize="xl">
            {name}
          </Text>
          <Badge colorScheme={testType === "PTE" ? "blue" : "green"}>{testType}</Badge>
        </HStack>

        <Image
          borderRadius="lg"
          src={imageUrl}
          alt={`${name}'s photo`}
          boxSize="300px"
          objectFit="cover"
        />
      </VStack>
    </Box>
  );
};
